/* Scrollbar Track */
::-webkit-scrollbar {
  background-color: #f8f8ff00;
  width: 8px;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
  border-radius: 0px;
}

/* Scrollbar Buttons */
::-webkit-scrollbar-button {
  background-color: #f0f0f0;
  border: 0px solid #ccc;
  border-radius: 5px;
  width: 0px;
  height: 0px;
}
